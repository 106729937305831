import gsap from "gsap";

let $app = document.querySelector(".app");

let $artData = document.querySelector(".art-data");
let $artPerso = document.querySelector(".art-perso p");
let $artTriggers = document.querySelectorAll(".art-trigger");
let $artImg = document.querySelector(".art-img");

let $artDataDesc = $artData.querySelector(".art-data-desc p");
let $artDataTitle = $artData.querySelector(".art-data-desc h3");

const artList = [
  {
    name: "Pietà",
    description:
      "La Pietà (prononcé : [pje'ta]) est un groupe en marbre de Michel-Ange situé dans la basilique Saint-Pierre du Vatican à Rome, représentant le thème biblique de la 'Vierge Marie douloureuse', tenant sur ses genoux le corps du Christ descendu de la croix avant sa mise au tombeau, sa Résurrection et son Ascension. Elle a été sculptée entre 1498 et 1499.",
    perso:
      "Pour moi, la Pietà représente parfaitement le côté hautain de certains artistes il y a 400 ans. Michelangelo a eu l'audace d'écrire son nom sur l'une des statues les plus iconiques de l'histoire de l'art, ce qui fait de lui, selon moi, un artiste avant-gardiste. À une époque où l'art était encore très religieux et anonyme, il a su se démarquer en signant son œuvre, comme si elle représentait à elle seule la fin de l'art anonyme et le début de l'artiste en tant que marqueur de son temps.",
  },
  {
    name: "La cathédrale de Koln",
    description:
      "La cathédrale de Cologne est une cathédrale catholique de style gothique située à Cologne, en Allemagne. Elle est le siège de l'archevêché de Cologne et de la métropole de l'Église catholique romaine en Allemagne. Elle est dédiée à saint Pierre et à sainte Marie. Fondée en 1248, la cathédrale a été achevée en 1880.",
    perso:
      "J'adore le style gothique, c'est sûrement l'un de mes styles architecturaux préférés. La cathédrale de Cologne est un exemple parfait de ce style. Elle est immense et majestueuse, un symbole de la ville de Cologne et de l'Allemagne. Elle arbore de gigantesques vitraux et des sculptures impressionnantes. C'est un lieu qui a traversé les siècles et qui continue de fasciner les visiteurs du monde entier, rappelant la grandeur de l'architecture gothique et son impact sur nos techniques modernes.",
  },
  {
    name: "La Cathédrale de Rouen",
    description:
      "La Cathédrale de Rouen est une série de peintures de Claude Monet réalisée entre 1892 et 1894. Elle représente la cathédrale Notre-Dame de Rouen, peinte à différentes heures du jour et dans diverses conditions atmosphériques.",
    perso:
      "C'est ici que, selon moi, l'intérêt et le sens du mouvement impressionniste prennent tout leur sens. Monet a su capturer l'essence même de la cathédrale de Rouen en la peignant à différentes heures de la journée. Il a su capturer la lumière et l'atmosphère de la ville de Rouen. C'est, pour moi, une des œuvres les plus emblématiques de l'impressionnisme. Avec cette série de peintures, Monet nous montre à quel point la couleur et la lumière sont des éléments essentiels pour la peinture. Bien que la photographie soit plus précise et fonctionne aussi avec la lumière, la peinture reste un art qui peut capturer des émotions et des sentiments que la photographie ne peut pas. Une réelle interprétation du monde s'offre à nous plus de 100 ans après.",
  },
  {
    name: "Nakagin Capsule Tower",
    description:
      "La Nakagin Capsule Tower est un immeuble de logements situé à Tokyo, au Japon. Il a été conçu par l'architecte Kisho Kurokawa et construit en 1972. Il fait partie des premiers exemples de l'architecture brutaliste et métaboliste, un mouvement architectural japonais des années 1960 et 1970.",
    perso:
      "Étant déjà très fan du brutalisme et en particulier dans l'architecture, ce bâtiment est pour moi la quintessence de la modernisation des habitats. Loin de ce qu'on essaye d'imaginer comme lieu de vie du futur mais plus comme une vision dystopique où ne règnent qu'optimisation et minimalisme. C'est un bâtiment qui a été conçu pour être modulaire et facilement remplaçable. Chaque capsule est un appartement complet, avec salle de bain, cuisine et chambre. Son style iconique et futuriste en fait une référence commune dans beaucoup de films de science-fiction comme Star Wars pour n'en citer qu'un.",
  },
  {
    name: "untitled",
    description:
      "Untitled est une photo du célèbre photographe William Eggleston. Elle a été prise en 1980. Elle représente une station essence au crépuscule. Animée par de vives couleurs malgré l'heure tardive, elle est devenue une des photos les plus emblématiques de l'artiste.",
    perso:
      "Cette photo est un exemple parfait de la beauté de l'ordinaire. Eggleston a su capturer la beauté d'un lieu banal et quotidien. Il a su capturer la lumière et les couleurs de la station essence pour en faire une œuvre d'art. C'est une photo qui nous montre que la beauté est partout autour de nous, il suffit juste de savoir la voir. Elle me rappelle que l'art est partout, même dans les endroits les plus inattendus. Et en plus de tout ça, elle rappelle à tous le savoir-faire de William Eggleston, qui est un des pionniers de la photographie en couleur.",
  },
  {
    name: "Mountains",
    description:
      "Mountains est une des nombreuses musiques du long métrage Interstellar. Elle est composée par Hans Zimmer et interprétée par l'orchestre philharmonique de Londres. Elle est utilisée dans le film lors de la scène où les astronautes découvrent la planète océan. C'est une musique de tension qui monte crescendo.",
    perso:
      "Mountains est l'une des meilleures compositions de Hans Zimmer, glaciale de symboles. Par exemple, chaque 'tic' de la musique est soigneusement placé à intervalle régulier de 1,25 seconde, ce qui fait 48 tics en tout. Dans le film, on nous parle de l'impact de la gravité sur le temps et, en conséquence, chaque tic correspond à 21 heures de temps écoulé sur Terre. C'est une musique qui nous fait ressentir la tension et l'urgence de la situation. Accordée avec l'un de mes instruments préférés, l'orgue, elle nous plonge dans un univers froid et inconnu.",
  },
  {
    name: "Melting Memories",
    description:
      "Melting Memories est une installation de l'artiste Refik Anadol. Elle a été présentée pour la première fois en 2018. Elle est composée de plusieurs écrans qui diffusent des images de cerveaux en train de fondre. Évidemment, nous ne les voyons pas directement; c'est une génération créée par ordinateur avec comme ressources un cerveau en activité. L'installation est accompagnée d'une musique électronique qui crée une ambiance hypnotique.",
    perso:
      "Une seule œuvre ne saurait rendre honneur à Refik Anadol. Sa contribution à la compréhension et à l'imagerie des pensées humaines est nombreuse. Il mêle de l'intelligence artificielle avec notre cerveau, nous, simples humains incapables de comprendre ce qui se trame dans notre propre crâne. La vision et l'interprétation de Refik Anadol sont, selon moi, l'une des meilleures et des plus compréhensibles de ce que pourrait être notre cerveau. C'est une œuvre qui nous rappelle que l'art et la science peuvent se rejoindre pour créer des œuvres fascinantes et innovantes.",
  },
];

function getImages(artName) {
  const normalizedArtName = artName
    .toLowerCase()
    .replace(/\s+/g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return `${normalizedArtName}`;
}

function updateArtData(artName) {
  const art = artList.find((item) => item.name === artName);
  if (art) {
    $artDataTitle.innerHTML = art.name;
    $artDataDesc.innerHTML = art.description;
    $artPerso.innerHTML = art.perso;
    $artImg.classList = getImages(art.name);
  }

  gsap.from($artDataDesc, {
    duration: 0.5,

    x: -50,
  });
  gsap.from($artPerso, {
    duration: 0.5,

    x: 50,
  });
  gsap.from($artImg, {
    duration: 0.5,

    scale: 0.7,
  });
}

updateArtData("Pietà");

$artTriggers.forEach((artTrigger) => {
  artTrigger.addEventListener("click", () => {
    if (artTrigger.classList.contains("trigger-next")) {
      const currentIndex = artList.findIndex(
        (item) => item.name === $artDataTitle.innerHTML
      );
      const nextIndex = (currentIndex + 1) % artList.length;
      updateArtData(artList[nextIndex].name);
    } else if (artTrigger.classList.contains("trigger-previous")) {
      const currentIndex = artList.findIndex(
        (item) => item.name === $artDataTitle.innerHTML
      );
      const previousIndex =
        (currentIndex - 1 + artList.length) % artList.length;
      updateArtData(artList[previousIndex].name);
    }
  });
});

document.addEventListener("keypress", (e) => {
  if (e.code === "Space") {
    const currentIndex = artList.findIndex(
      (item) => item.name === $artDataTitle.innerHTML
    );
    const nextIndex = (currentIndex + 1) % artList.length;
    updateArtData(artList[nextIndex].name);
  } else {
    return;
  }
});

//=======> IMG tracking <=======//

window.onload = function () {
  let $artScene = document.querySelector(".art-scene");
  let $shine = document.querySelector(".shine");

  var update = function (e) {
    let x = e.clientX / window.innerWidth - 0.5;
    let y = e.clientY / window.innerHeight - 0.5;
    let angle = Math.atan2(y, x) * (180 / Math.PI);
    let invertAngle = Math.atan2(-y, -x) * (180 / Math.PI);

    $artImg.style.transform = `translateY(${y * 300}px) translateX(${
      x * 300
    }px) rotateX(${y * 20}deg) rotateY(${x * 20}deg)`;
    $artImg.style.transition = "transform 0s";

    $shine.style.background = `linear-gradient(${angle}deg, rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0) 70%`;
  };

  let $onMouseMoveHandler = function (e) {
    update(e);
  };

  let $onMouseLeaveHandler = function (e) {
    let style = "scale(0.99)";
    $artImg.style.transform = style;
    $artImg.style.transition = "transform 0.3s";
  };

  $artScene.onmouseleave = $onMouseLeaveHandler;
  $artScene.onmousemove = $onMouseMoveHandler;
};
